<div class="project-detail" [formGroup]="form">
  <ng-container *ngIf="showURL">
    <label *ngIf="textLabel.length > 0" class="flex items-center justify-between gap-2">
      <span>{{ textLabel }} <span class="text-red" *ngIf="fieldRequired">*</span></span>
    </label>
    <div class="flex justify-between items-center gap-2">
      <input type="text" class="w-full" pInputText [formControlName]="textControlName" placeholder="https://" />
      <a *ngIf="showToggle && form.controls[textControlName].enabled" class="text-xs text-blue-light cursor-pointer" (click)="onToggleUrl()">Upload</a>
    </div>
    <small *ngIf="hasError" class="text-xs text-red">{{ getError(textControlName) }}</small>
  </ng-container>
  <ng-container *ngIf="!showURL">
    <label *ngIf="fileLabel.length > 0" class="flex items-center justify-between gap-2">
      <span>{{ fileLabel }} <span class="text-red" *ngIf="fieldRequired">*</span></span>
    </label>
    <div class="flex justify-between items-center gap-2">
      <label class="file-upload w-full" [class.error]="hasError">
        <div class="flex flex-row-reverse gap-2 items-center">
          <i
            *ngIf="showDelete && fileName !== ''"
            class="fa-light fa-trash text-red cursor-pointer"
            (click)="onRemoveLogo()"
            appendTo="body"
            pTooltip="Delete"
            tooltipPosition="top"></i>
          <label *ngIf="form.controls[fileControlName].enabled" [for]="fileControlName"
            ><i class="fa-regular fa-upload" tooltipPosition="top" appendTo="body" pTooltip="Upload"></i
          ></label>
        </div>
        <div class="file-name">
          <span>{{ fileName || placeholder }}</span>
        </div>
      </label>
      <a *ngIf="showToggle && form.controls[fileControlName].enabled" class="text-xs text-blue-light cursor-pointer text-nowrap" (click)="onToggleUrl()">{{
        toggleLabel
      }}</a>
    </div>
    <input [id]="fileControlName" class="hidden" type="file" [accept]="accept" (click)="onClickFile($event)" (change)="onFileChange($event)" />
    <small *ngIf="hasError" class="text-xs text-red">
      {{ getError(fileControlName) }}
    </small>
  </ng-container>
</div>
