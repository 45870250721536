import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { fieldRequired, getError, hasError, website } from '@app/shared/utils/validators';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'lfx-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputTextModule]
})
export class FileUploadInputComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public fileControlName: string;
  @Input() public fileValidators: ValidatorFn[] = [];
  @Input() public fileLabel: string = '';
  @Input() public textControlName: string;
  @Input() public textValidators: ValidatorFn[] = [website];
  @Input() public textLabel: string = '';
  @Input() public toggleLabel: string = 'Enter URL';
  @Input() public showToggle: boolean = true;
  @Input() public accept: string = '*/*';
  @Input() public fileName: string | null = null;
  @Input() public showURL: boolean = false;
  @Input() public showDelete: boolean = false;
  @Input() public placeholder: string = 'Upload Logo';

  public ngOnInit(): void {
    if (this.textControlName && this.form.value[this.textControlName]) {
      this.onToggleUrl();
    }

    if (!this.fileName) {
      this.form.controls[this.fileControlName].setValidators(this.fileValidators);
    }
  }

  public get hasError(): boolean {
    return hasError(this.fileControlName, this.form) || hasError(this.textControlName, this.form);
  }

  public getError(controlName: string): string {
    return getError(controlName, this.form);
  }

  public get fieldRequired(): boolean {
    return fieldRequired(this.fileControlName, this.form) || fieldRequired(this.textControlName, this.form);
  }

  public onRemoveLogo(): void {
    this.fileName = '';
    this.form.patchValue({
      [this.fileControlName]: null,
      [this.textControlName]: null
    });
  }

  public onClickFile(event: Event) {
    // Required to triggered change event for same file selection case
    const element = event.target as HTMLInputElement;
    element.value = '';
  }

  public onFileChange(event: Event): void {
    const file = event.target as HTMLInputElement;
    if (file?.files?.length) {
      const tmpFile = file.files[0];
      this.fileName = tmpFile.name;
      this.form.patchValue({
        [this.fileControlName]: tmpFile
      });

      if (this.showToggle) {
        this.form.controls[this.textControlName].disable();
      }
    } else {
      this.fileName = '';
      this.form.patchValue({
        [this.fileControlName]: null,
        [this.textControlName]: null
      });

      this.form.controls[this.textControlName].enable();
    }
    this.form.controls[this.fileControlName].markAsTouched();
    this.form.controls[this.fileControlName].updateValueAndValidity();
  }

  public onToggleUrl(): void {
    if (!this.showURL) {
      this.form.controls[this.textControlName].enable();
      this.form.controls[this.textControlName].addValidators(this.textValidators);
      this.form.patchValue({
        [this.fileControlName]: null
      });
      this.form.controls[this.fileControlName].disable();
    } else {
      this.form.controls[this.fileControlName].addValidators(this.fileValidators);
      this.form.controls[this.fileControlName].enable();
      this.form.controls[this.textControlName].disable();
    }

    this.fileName = '';

    this.form.controls[this.textControlName].updateValueAndValidity();
    this.form.controls[this.fileControlName].updateValueAndValidity();

    this.showURL = !this.showURL;
  }
}
